<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_sms") }}
            </b-card-title>
          </b-card-header>

          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>

              <b-button
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
                <b-icon icon="funnel"></b-icon>
              </b-button>
            </b-card-text>
            <b-table
              :filter="searchQuery"
              small
              hover
              :fields="tableColumns"
              :items="records"
              responsive
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="records.length == 0"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
              <template #cell(queue)="data">
                <b-badge variant="light-primary">
                  {{
                    queues.find((e) => e.internal_name == data.value)
                      ? queues.find((e) => e.internal_name == data.value)
                          .display_name
                      : data.value
                  }}
                </b-badge>
              </template>
              <template #cell(status)="data">
                <feather-icon
                  size="18"
                  class="text-success"
                  v-if="data.value"
                  icon="CheckCircleIcon"
                ></feather-icon>
                <feather-icon
                  size="18"
                  class="text-danger"
                  v-else
                  icon="XCircleIcon"
                ></feather-icon>
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  boundary="viewport"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item @click="updateSelected(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    records.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="records.length"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_records"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Operator -->
          <validation-provider
            #default="validationContext"
            name="Operator"
            rules="required"
          >
            <b-form-group
              :label="lang('t_operator')"
              label-for="operator"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="currentData.operator"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="operators"
                :reduce="(val) => val.internal_name"
                label="display_name"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Display Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              :label="lang('t_displayName')"
              label-for="display_name"
            >
              <b-form-input
                id="display_name"
                v-model="currentData.display_name"
                :state="getValidationState(validationContext)"
                trim
              />
              <!--
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <!-- User Name -->
          <validation-provider
            #default="validationContext"
            name="User"
            rules="required"
          >
            <b-form-group :label="lang('t_userName')" label-for="user_name">
              <b-form-input
                id="user_name"
                v-model="currentData.user_name"
                :state="getValidationState(validationContext)"
                trim
              />
              <!--
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required"
          >
            <b-form-group :label="lang('t_password')" label-for="password">
              <b-form-input
                id="password"
                v-model="currentData.password"
                :state="getValidationState(validationContext)"
                trim
              />
              <!--
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <!-- title -->
          <validation-provider
            #default="validationContext"
            name="title"
            rules="required"
          >
            <b-form-group :label="lang('t_title')" label-for="title">
              <b-form-input
                id="title"
                v-model="currentData.title"
                :state="getValidationState(validationContext)"
                trim
              />
              <!--
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <!-- Sender number -->
          <validation-provider
            #default="validationContext"
            name="Sender"
            rules="required"
          >
            <b-form-group
              :label="lang('t_senderNumber')"
              label-for="sender_number"
            >
              <b-form-input
                id="sender_number"
                v-model="currentData.sender_id"
                :state="getValidationState(validationContext)"
                trim
              />
              <!--
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <!-- Account Code -->
          <validation-provider
            #default="validationContext"
            name="Account"
            rules="required"
          >
            <b-form-group
              :label="lang('t_accountCode')"
              label-for="account_code"
            >
              <b-form-input
                id="account_code"
                v-model="currentData.account_code"
                :state="getValidationState(validationContext)"
                trim
              />
              <!--
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <!-- API Key -->
          <validation-provider
            #default="validationContext"
            name="API"
            rules="required"
          >
            <b-form-group :label="lang('t_apiKey')" label-for="api_key">
              <b-form-input
                id="api_key"
                v-model="currentData.api_key"
                :state="getValidationState(validationContext)"
                trim
              />
              <!--
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="API"
            rules="required"
          >
            <b-form-group :label="lang('t_prefix')" label-for="prefix">
              <b-form-input
                id="prefix"
                v-model="currentData.prefix"
                :state="getValidationState(validationContext)"
                trim
              />
              <!--
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <!-- Form Actions
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{lang('t_add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{lang('t_cancel')}}
            </b-button>
          </div>
         -->
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useList from './useList'
import storeModule from '../storeModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // UsersListFilters,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BCardText,
    vSelect,
  },
  computed: {
    items() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.records.slice(start, end);
    },

  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      isEdit: false,
      currentData: {
        internal_name: '',
        display_name: '',
        user_name: '',
        password: '',
        title: '',
        sender_id: '',
        create_date: '',
        operator: 'nac_telekom',
        account_code: '',
        api_key: '',
        prefix: '',
      },
      operators: [
        { internal_name: 'nac_telekom', display_name: 'Nac Telekom' },
        { internal_name: 'hermes_iletisim', display_name: 'Hermes İletişim' },
        { internal_name: 'net_gsm', display_name: 'Net GSM' },
        { internal_name: 'codec', display_name: 'CODEC FASTSMS' },
        { internal_name: 'sempico', display_name: 'Sempico' },
        { internal_name: 'codec_bulk', display_name: 'CODEC BULK' },
        { internal_name: 'interaktif', display_name: 'İnteraktif' },
        { internal_name: 'organik_haberlesme', display_name: 'Organik Haberleşme' },
        { internal_name: 'ekomesaj', display_name: 'Ekomesaj' },
        { internal_name: 'varan_otp', display_name: 'Varan OTP' },
        { internal_name: 'posta_guvercini', display_name: 'Posta Güvercini' },
      ],
      columns: [],
      records: [],
      projects: [],
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
      tableColumns: [
        { key: 'operator', label: globalThis._lang('t_operator'), sortable: true },
        { key: 'display_name', label: globalThis._lang('t_displayName'), sortable: true },
        { key: 'user_name', label: globalThis._lang('t_userName'), sortable: true },
        { key: 'password', label: globalThis._lang('t_password'), sortable: true },
        { key: 'title', label: globalThis._lang('t_title'), sortable: true },
        { key: 'sender_id', label: globalThis._lang('t_senderNumber'), sortable: true },
        { key: 'account_code', label: globalThis._lang('t_accountCode'), sortable: true },
        { key: 'api_key', label: globalThis._lang('t_apiKey'), sortable: true },
        { key: 'prefix', label: globalThis._lang('t_prefix'), sortable: true },
        { key: 'actions', label: '', sortable: false, thStyle: { width: '5vh' } },
      ]
    }
  },
  mounted: function () {
    this.get_records();
    this.get_columns();
    this.get_project();
  },
  methods: {
    get_records: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/SmsSettings`)
      ).data;
      console.log("SmsSettings", response);
      this.records = response;
    },
    get_project: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/Project`)
      ).data;
      console.log("projects", response);
      this.projects = response;
    },

    check_data() {
      if (this.currentData.display_name == '' || this.currentData.user_name == '' || this.currentData.password == '') {
        return true;
      }
      return false;
    },

    add_records: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang('t_warning'),
          text: globalThis._lang('t_pleaseDoNotLeaveAnyBlankFields'),
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return;
      }

      var response;
      if (!this.isEdit) {
        console.log("this.currentData", this.currentData);
        response = (
          await this.$http_in.post(
            `spv/v1/SmsSettings`,
            { data: JSON.stringify(this.currentData) }

          )
        ).data;
      } else {
        response = (
          await this.$http_in.put(
            `spv/v1/SmsSettings`,
            {
              data: JSON.stringify(this.currentData),
            }
          )
        ).data;
      }

      // if (response != null || response != undefined) {
      //   if (response.command == 'UPDATE') {
      //     this.alert_message = `${this.black_list_number.number} ${globalThis._lang("t_hasBeenUpdatedSuccessfully")}`;
      //     this.alert_type = 'success';
      //     this.is_alert = true;
      //   } else {
      //     this.alert_message = globalThis._lang("t_hasBeenCreatedSuccessfully");
      //     this.alert_type = 'success';
      //     this.is_alert = true;
      //   }
      // }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      var response = (
        await this.$http_in.delete(`spv/v1/SmsSettings/${this.currentData._id}`)
      ).data;


      if (response != null || response != undefined) {
        this.$swal({
          icon: 'success',
          title: this.lang('t_processSuccess'),
          text: this.lang('t_customersHaveBeenSuccessfullyDeleted'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        internal_name: '',
        display_name: '',
        user_name: '',
        password: '',
        title: '',
        sender_id: '',
        create_date: '',
        operator: 'nac_telekom',
        account_code: '',
        api_key: '',
        prefix: '',
      }
    },
    updateSelected(item) {
      this.currentData = item;
      console.log("item", item);
      this.isEdit = true;
      this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang('t_deleteRecord'),
        text: this.lang('t_areYouSureYouWantToDeleteTheSelectedRecord'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delete_record();
        }
      })
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      contact: '',
    }

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const isAddNewUserSidebarActive = ref(false)

    const operators = [
      { label: 'Nac Telekom', value: 'nac_telekom' },
      { label: 'Hermes İletişim', value: 'hermes_iletişim' },
    ]

    const extensOptions = [
      { label: '123', value: '123' },
      { label: '456', value: '456' },
      { label: '9999', value: '9999' },
      { label: '654', value: '654' },
    ]
    const projectOptions = [
      { label: 'Abc', value: '1' },
      { label: 'Proje2', value: '2' },
      { label: 'Proje3', value: '3' },
      { label: 'Proje4', value: '4' },
    ]
    const profileOptions = [
      { label: 'Admin', value: '1' },
      { label: 'Profil2', value: '2' },
      { label: 'Profil3', value: '3' },
      { label: 'Profil4', value: '4' },
    ]


    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      operators,
      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
